<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0 pt-0">
      <v-card-title class="px-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon class="ma-0">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                id="idCodigo"
                v-model="natureza.codigo"
                label="Código *"
                :rules="[rules.requiredCode, rules.digit, rules.exist]"
                required
                v-mask="'#'"
                maxlength="1"
                minlength="1"
                :hint="codigoHint"
                hide-details="auto"
                persistent-hint
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                id="idNome"
                v-model="natureza.nome"
                :rules="[rules.requiredName]"
                label="Nome *"
                dense
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>
        <div class="text-red pl-2" style="color: #610006" v-if="invalidForm">Preencher todos os campos obrigatórios</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close" id="btnCancel" class="btn-ag-tertiary"> Cancelar</v-btn>
        <v-btn @click="save()" class="btn-ag-primary" id="btnConfirmOredit" :disabled="isEnableSave">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ModalNatureTypeNew',
  props: ['natureTypes'],

  data() {
    return {
      valid: true,
      codigoHint: 'Preencha 1 número (1-9)',
      natureza: {
        codigo: '',
        nome: '',
      },
      existentCode: true,
      invalidForm: false,
      titulo: 'Adicionar tipo',
      labelBotao: 'Salvar',
      show: false,
      rules: {
        requiredCode: (code) => !!code || 'Preencha 1 número (1-9)',
        requiredName: (name) => !!name || 'Preencha um nome',
        digit: (code) => /^\d{1}$/.test(code) || 'Preencha 1 número (1-9)',
        exist: (code) => !this.codeExist(code) || 'Este código já existe! Preencha outro código',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },
  validations: {
    natureza: {
      codigo: { required }, // Matches this.firstName
      nome: { required }, // Matches this.lastName
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    isEnableSave() {
      let count = 0;
      if (![undefined, null].includes(this.natureza.codigo)) {
        if (!this.isEmpty(this.natureza.codigo)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.natureza.nome)) {
        if (!this.isEmpty(this.natureza.nome)) {
          ++count;
        }
      }
      if (this.existentCode) {
        --count;
      }
      if (count === 2) {
        return false;
      } else {
        return true;
      }
    },
  },

  created() {
    this.init();
  },

  methods: {
    isEmpty(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    async save() {
      this.$v.natureza.$touch();

      if (this.$v.natureza.$error || this.$v.natureza.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
      let nature = {
        code: this.natureza.codigo,
        name: this.natureza.nome,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      };
      const response = await eSocialService.rubricAutomationConfig.natureTypesSave(nature);
      if (response) {
        (this.natureza.codigo = ''), (this.natureza.nome = ''), this.$emit('refresh');
        this.close();
        this.showToast('Tipo da rubrica adicionado com sucesso!.', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar tipo', 'warning', 78);
      }
    },

    codeExist(code) {
      if (this.natureTypes.some((item) => item.code === code)) {
        this.existentCode = true;
        return true;
      } else {
        this.existentCode = false;
        return false;
      }
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open() {
      this.natureza = {};
      this.titulo = 'Adicionar tipo';
      this.labelBotao = 'Salvar';
      this.show = true;
    },

    close() {
      this.natureza = {};
      this.invalidForm = false;
      this.$refs.form.resetValidation();
      this.show = false;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/input.scss';
@import './src/design/components/datepicker.scss';

.v-card__actions {
  padding: 16px !important;
}
::v-deep .v-text-field__details .v-messages {
  text-align: right !important;
}
::v-deep .v-text-field__details .v-messages__message {
  background: none !important;
}
::v-deep .v-text-field__details .v-messages.theme--light.error--text {
  text-align: left !important;
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left !important;
}
</style>
